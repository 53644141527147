import { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem("token");
        if (!token) {
            navigate("/login");
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data === "ادمان") {
                navigate("/addiction");
            }
            else if (res.data === "خزنة"){
                navigate("/admin/finance");
            }else{
                sessionStorage.removeItem("token");
                navigate("/login");
            }
        } catch (err){
            console.error(err)
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (loading) return;

        setLoading(true);
        setError("");

        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/login`, {
                adminEmail: email.trim().toLowerCase(),
                adminPassword: password,
            });

            sessionStorage.setItem("token", res.data.refreshToken);

            switch (res.data.role) {
                case "عامل":
                    navigate(`/salesandreturns/${res.data.place}`);
                    break;
                case "مسئول":
                    navigate("/admin/finance");
                    break;
                default:
                    if (res.data.place === "اخوة رب") {
                        navigate("/admin/god");
                    } else if (res.data.place === "حضانة") {
                        navigate(`/nursery/${res.data.place}`);
                    } else if (res.data.place === "ادمان") {
                        navigate("/addiction");
                    }
                    else if (res.data.place === "الاباء") {
                        navigate("/father/salary");
                    }
                    break;
            }
        } catch (err) {
            setError(err.response?.data?.error || "حدث خطأ ما. حاول مرة أخرى.");
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="login-page w-[90%] m-auto">
            <Navbar />
            <div className="login-heading mt-5">
                <h1 className="text-center text-3xl font-bold">قم بتسجيل الدخول الآن!</h1>
            </div>
            <div className="form w-[90%] lg:w-[50%] m-auto">
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-4">
                        <label className="text-xl">البريد الإلكتروني</label>
                        <input
                            type="email"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="text-xl">كلمة المرور</label>
                        <input
                            type="password"
                            className="w-full border mt-3 border-gray-300 rounded-md focus:outline-none focus:ring focus:border-blue-300 py-2"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                    </div>
                    <div className="submit mt-6 text-center">
                        <button
                            type="submit"
                            className={`bg-black text-white py-3 px-8 rounded w-[180px] text-xl hover:cursor-pointer hover:bg-black/80 duration-200 ${loading ? "opacity-50 cursor-not-allowed" : ""}`}
                            disabled={loading}
                        >
                            {loading ? "جاري التحميل..." : "تسجيل الدخول"}
                        </button>
                    </div>
                    {error && (
                        <div className="Error-section mt-6 text-center">
                            <p className="text-lg text-red-600">{error}</p>
                        </div>
                    )}
                </form>
            </div>
        </div>
    );
};

export default Login;
