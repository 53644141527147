import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Navbar from '../../components/Navbar';
import Logo from '../../imgs/logo.png';

const SanatoriumDetails = () => {
    const { id } = useParams();
    const [sanatorium, setSanatorium] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({});

    const fetchSanatorium = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}sanatorium/${id}`);
            setSanatorium(response.data);
            setFormData(response.data);
        } catch (error) {
            console.error('Error fetching sanatorium details:', error);
        }
    };

    useEffect(() => {
        fetchSanatorium();
    }, [id]);

    const handlePrint = () => {
        window.print();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    const handleExceptionsChange = (e) => {
        setFormData(prev => ({
            ...prev,
            sanatoriumExceptions: e.target.value.split(',').map(item => item.trim())
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}sanatorium/${id}`, formData);
            setSanatorium(formData);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating sanatorium:', error);
        }
    };

    if (!sanatorium) {
        return <div>Loading...</div>;
    }

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="my-4 hidden print:block">
                <img src={Logo} alt="لوجو" className="w-32 h-32" />
            </div>
            <div className="w-[90%] mx-auto p-8 bg-white rounded-xl shadow-lg">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">تفاصيل المصحة</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div>
                        <label className="block text-lg font-medium mb-2">الأسم</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumName"
                                value={formData.sanatoriumName || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumName || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">العنوان</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumAddress"
                                value={formData.sanatoriumAddress || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumAddress || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الإداري</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumAdmin"
                                value={formData.sanatoriumAdmin || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumAdmin || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم هاتف الإداري</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumAdminPhone"
                                value={formData.sanatoriumAdminPhone || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumAdminPhone || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الإداري المالي</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumFinanceAdmin"
                                value={formData.sanatoriumFinanceAdmin || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumFinanceAdmin || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم هاتف الإداري المالي</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumFinanceAdminPhone"
                                value={formData.sanatoriumFinanceAdminPhone || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumFinanceAdminPhone || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف التوصيل</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="delviryExpensee"
                                value={formData.delviryExpensee || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.delviryExpensee || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مسؤول التوصيل</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="delviryExpenseeresponsible"
                                value={formData.delviryExpenseeresponsible || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.delviryExpenseeresponsible || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">هاتف مسؤول التوصيل</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="delviryExpenseeresponsiblePhone"
                                value={formData.delviryExpenseeresponsiblePhone || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.delviryExpenseeresponsiblePhone || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف الإقامة</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="residenceExpense"
                                value={formData.residenceExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.residenceExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">المصاريف الشخصية</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="personalExpense"
                                value={formData.personalExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.personalExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">المصاريف الطبية</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="medicalExpense"
                                value={formData.medicalExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.medicalExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف السجائر</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="cigiratesExpense"
                                value={formData.cigiratesExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.cigiratesExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف التحاليل</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="medicalTestsExpenes"
                                value={formData.medicalTestsExpenes || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.medicalTestsExpenes || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف الكانتين</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="kantennExpense"
                                value={formData.kantennExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.kantennExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف الأشعة</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="xraysExpense"
                                value={formData.xraysExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.xraysExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف أخرى</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="otherExpenses"
                                value={formData.otherExpenses || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.otherExpenses || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف جلسات العلاج</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="therapySessionExpense"
                                value={formData.therapySessionExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.therapySessionExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف إزالة السموم</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="DetoksExpense"
                                value={formData.DetoksExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.DetoksExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف الأدوية الإضافية</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="extramedicineExpense"
                                value={formData.extramedicineExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.extramedicineExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف التحاليل والأشعة الإضافية</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="extramedicineTestsandxraysExpense"
                                value={formData.extramedicineTestsandxraysExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.extramedicineTestsandxraysExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف العودة</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="goBackExpense"
                                value={formData.goBackExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.goBackExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مصاريف تغيير المكان</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="changePlaceExpense"
                                value={formData.changePlaceExpense || ''} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.changePlaceExpense || "لا يوجد"}</p>
                        }
                    </div>
                    <div className="col-span-2">
                        <label className="block text-lg font-medium mb-2">الاستثناءات</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="sanatoriumExceptions"
                                value={formData.sanatoriumExceptions?.join(', ') || ''} 
                                onChange={handleExceptionsChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{sanatorium.sanatoriumExceptions?.join(', ') || "لا يوجد"}</p>
                        }
                    </div>
                </div>
                <div className="text-center mt-4 space-x-4">
                    {isEditing ? (
                        <>
                            <button
                                className="bg-green-600 text-white px-4 py-2 rounded-lg w-[25%]"
                                onClick={handleSave}
                            >
                                حفظ
                            </button>
                            <button
                                className="bg-gray-600 text-white px-4 py-2 rounded-lg w-[25%]"
                                onClick={() => setIsEditing(false)}
                            >
                                إلغاء
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="bg-yellow-500 text-white px-4 py-2 rounded-lg w-[25%] mx-2"
                                onClick={() => setIsEditing(true)}
                            >
                                تعديل
                            </button>
                            <button
                                className="bg-green-600 text-white px-4 py-2 rounded-lg w-[25%] mx-2"
                                onClick={handlePrint}
                            >
                                طباعة
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SanatoriumDetails;