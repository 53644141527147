import axios from "axios";
import { useEffect } from "react";
import { useNavigate, Outlet, useLocation } from "react-router-dom";

const AmbitiousAuth = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'الاباء') {
                navigate('/login');
            }
        } catch {
            navigate('/login');
        }
    };

    // useEffect(() => {
    //     verifyAdmin();
    // }, [location]);

    return <Outlet />;
};

export default AmbitiousAuth;
