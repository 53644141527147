import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const AddictFormTable = () => {
    const [data, setData] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form`);
            setData(response.data);
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء جلب البيانات.', 'error');
        }
    };

    const deleteData = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف استمارة الحالة بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}addict-form/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف حالة بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف استمارة الحالة بنجاح.', 'success');
                    fetchData();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredData = data.filter((item) =>
        item.addict?.addictName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedData = filteredData.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">عدد الحالات الحالية بالمصحة</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="ابحث عن الحالة"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/addictform'}>
                        <button
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف استمارة جديدة
                        </button>
                    </Link>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">اسم الحالة</th>
                                <th className="px-4 py-3">اسم المصحة</th>
                                <th className="px-4 py-3">إجمالي المصاريف</th>
                                <th className="px-4 py-3">تفاصيل</th>
                                <th className="px-4 py-3">حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.filter((item) => item?.addict && (!item?.endDate || new Date(item.endDate) > new Date())).map((item) => (
                                <tr key={item._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{item.addict?.addictName || 'غير متوفر'}</td>
                                    <td className="px-4 py-3 text-center">{item.sanatorium?.sanatoriumName || 'غير متوفر'}</td>
                                    <td className="px-4 py-3 text-center">{item.allExpense}</td>
                                    <td className="px-4 py-3 text-center">
                                        <Link to={'' + item._id}>
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">تفاصيل</button>
                                        </Link>
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            onClick={() => deleteData(item._id)}
                                            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />

                <h1 className="text-4xl text-center font-extrabold my-10 text-red-600">عدد الحالات الحالية خارج المصحة</h1>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-red-600 text-white">
                            <tr>
                                <th className="px-4 py-3">اسم الحالة</th>
                                <th className="px-4 py-3">اسم المصحة</th>
                                <th className="px-4 py-3">إجمالي المصاريف</th>
                                <th className="px-4 py-3">تفاصيل</th>
                                <th className="px-4 py-3">حذف</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedData.filter((item) => item?.addict && item?.endDate && new Date(item.endDate) < new Date()).map((item) => (
                                <tr key={item._id} className="border-b hover:bg-gray-100">
                                    <td className="px-4 py-3 text-center">{item.addict?.addictName || 'غير متوفر'}</td>
                                    <td className="px-4 py-3 text-center">{item.sanatorium?.sanatoriumName || 'غير متوفر'}</td>
                                    <td className="px-4 py-3 text-center">{item.allExpense}</td>
                                    <td className="px-4 py-3 text-center">
                                        <Link to={'' + item._id}>
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">تفاصيل</button>
                                        </Link>
                                    </td>
                                    <td className="px-4 py-3 text-center">
                                        <button
                                            onClick={() => deleteData(item._id)}
                                            className="bg-red-500 hover:bg-red-700 text-white py-2 px-4 rounded"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default AddictFormTable;