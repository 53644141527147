import React, { useEffect } from 'react';
import Navbar from '../../components/Navbar';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

const AdminsFinance = () => {
    const navigate = useNavigate();

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            navigate('/login');
        }
    };

    const downloadAllCSVs = async () => {
        const files = [
            { endpoint: 'blessing/csv', filename: 'blessings.csv' },
            { endpoint: 'servedcontract/csv', filename: 'contracts.csv' },
            { endpoint: 'locker/incomes/csv', filename: 'income.csv' },
            { endpoint: 'locker/expenses/csv', filename: 'expenses.csv' },
            { endpoint: 'get/products/csv', filename: 'products.csv' },
        ];

        try {
            await Promise.all(
                files.map(async (file) => {
                    const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}${file.endpoint}`, {
                        responseType: 'blob',
                    });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', file.filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
            );
        } catch (error) {
            console.error('Error downloading CSV files:', error);
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    return (
        <div className="bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto py-8">
                <h1 className="text-center text-4xl font-bold text-gray-800 mb-8">اهلا مسئول المالية</h1>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
                    <div className="bg-gradient-to-r from-blue-500 to-blue-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">تنزيل النسخ الاحطياطية</h2>
                        <button onClick={downloadAllCSVs} className="w-full py-2 px-4 bg-white text-blue-600 font-bold rounded-lg">تنزيل</button>
                    </div>
                    <div className="bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">حركة الخزنة</h2>
                        <Link to={'/finance'}>
                            <button className="w-full py-2 px-4 bg-white text-green-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-red-500 to-red-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">حركة الخزنة (المحذوفات)</h2>
                        <Link to={'/finance/deleted'}>
                            <button className="w-full py-2 px-4 bg-white text-red-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-purple-500 to-purple-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">صفحات المصروفات</h2>
                        <Link to={'/admin/expenses'}>
                            <button className="w-full py-2 px-4 bg-white text-purple-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-yellow-500 to-yellow-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">صفحات الايرادات</h2>
                        <Link to={'/admin/incomes'}>
                            <button className="w-full py-2 px-4 bg-white text-yellow-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-indigo-500 to-indigo-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">حركة المبيعات</h2>
                        <Link to={'/admin/sales'}>
                            <button className="w-full py-2 px-4 bg-white text-indigo-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-indigo-500 to-indigo-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">حركة المرجعات</h2>
                        <Link to={'/admin/returns'}>
                            <button className="w-full py-2 px-4 bg-white text-indigo-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-teal-500 to-teal-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">الحضانة</h2>
                        <Link to={'/finance/accept'}>
                            <button className="w-full py-2 px-4 bg-white text-teal-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-pink-500 to-pink-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">المنتجات</h2>
                        <Link to={'/finance/accept/products'}>
                            <button className="w-full py-2 px-4 bg-white text-pink-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-to-r from-gray-500 to-gray-600 text-white rounded-lg shadow-lg p-6">
                        <h2 className="text-2xl font-semibold mb-4">صفحات الاضافات و البحوثات</h2>
                        <Link to={'/admin/add/all'}>
                            <button className="w-full py-2 px-4 bg-white text-gray-600 font-bold rounded-lg">انتقل</button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AdminsFinance;
