import { useState } from "react";

const AddServed = () => {
    const [formData, setFormData] = useState({
        serial: "",
        fullName: "",
        disabilityType: "",
        subCategory: "",
        address: "",
        churchArea: false,
        serviceCard: false,
        pension: false,
        works: false,
        jobType: "",
        talents: "",
        other: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    return (
        <div className="min-h-screen bg-gray-900 p-10 flex flex-col items-center text-white">
            <h1 className="text-4xl font-bold mb-8">بيانات المخدومين</h1>
            <form className="bg-gray-800 p-6 rounded-xl shadow-lg w-full max-w-2xl">
                <div className="grid grid-cols-1 gap-4">
                    <input type="text" name="serial" placeholder="رقم تسلسلي" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="fullName" placeholder="الأسم رباعي" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="text" name="disabilityType" placeholder="نوع الإعاقة" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="subCategory" placeholder="فئة فرعية" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="address" placeholder="العنوان" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="churchArea" onChange={handleChange} /> محيط الكنيسة
                    </label>
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="serviceCard" onChange={handleChange} /> كارنية الخدمات
                    </label>
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="pension" onChange={handleChange} /> معاش
                    </label>
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="works" onChange={handleChange} /> يعمل
                    </label>
                    <input type="text" name="jobType" placeholder="نوع العمل" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="talents" placeholder="المواهب" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="other" placeholder="أخرى" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="phone" placeholder="رقم التليفون" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <button type="submit" className="bg-blue-500 p-3 rounded w-full hover:bg-blue-600">إرسال</button>
                </div>
            </form>
        </div>
    );
};

export default AddServed;
