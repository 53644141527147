import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import AdminAddPage from "./pages/financial/AdminAddPage";
import AddPlace from "./pages/financial/AddPlace";
import AddCategory from "./pages/financial/AddCategory";
import AddProduct from "./pages/financial/AddProduct";
import AddWorker from "./pages/financial/AddWorker";
import AddFamily from "./pages/god's brothers/AddFamily";
import SearchFamily from "./pages/god's brothers/SearchFamily";
import SalesAndReturnsPage from "./pages/financial/SalesAndReturnsPage";
import AddAdmin from "./pages/addAdmin";
import AdminFinance from "./pages/financial/AdminFinance";
import AdminsFinance from "./pages/financial/AdminsFinance";
import AdminGod from "./pages/god's brothers/AdminGod";
import AddExpenses from "./pages/financial/AddExpenses";
import AddWorkerSalary from "./pages/financial/AddWorkerSalary";
import WorkerSalary from "./pages/financial/WorkerSalary";
import ServedSalary from "./pages/financial/ServedSalary";
import NotFound from "./pages/NotFound";
import SalesPage from "./pages/financial/SalesPage";
import UpdateProducts from "./pages/financial/UpdateProducts";
import UpdateCategory from "./pages/financial/UpdateCategory";
import UpdatePlace from "./pages/financial/UpdatePlace";
import AddIncomes from "./pages/financial/AddIncomes";
import AddAdvance from "./pages/financial/AddAdvance";
import AdvanceHistory from "./pages/financial/AdvanceHistory";
import ExpensePage from "./pages/financial/Expenses";
import IncomePage from "./pages/financial/Incomes";
import Workers from "./pages/financial/Workers";
import AddPenalety from "./pages/financial/AddPenalety";
import PenaletyHistory from "./pages/financial/PanaletyHistory";
import NonWorkerSalary from "./pages/financial/NonWorkerSalary";
import AddContract from "./pages/financial/Contract/AddContract";
import ContractHistroy from "./pages/financial/Contract/ContractHistory";
import AddOccasion from "./pages/financial/Blessing/AddOccasion";
import UpdateOccasion from "./pages/financial/Blessing/UpdateOccasion";
import AddBlessing from "./pages/financial/Blessing/AddBlessing";
import AddBlessingAll from "./pages/financial/Blessing/AddBlessingAll";
import BlessingHistory from "./pages/financial/Blessing/BlessingHistory";
import ServedContractHistory from "./pages/financial/Contract/ServedContractHistory";
import AddChild from "./pages/financial/Nursery/AddChild";
import ChildrenData from "./pages/financial/Nursery/Children";
import Nursury from "./pages/financial/Nursery/Nursery";
import AddList from "./pages/financial/Nursery/AddList";
import List from "./pages/financial/Nursery/List";
import AddDailyList from "./pages/financial/Nursery/AddDailyList";
import DailyList from "./pages/financial/Nursery/DailyList";
import AddVacation from "./pages/financial/Vacations/AddVacation";
import VacationHistory from "./pages/financial/Vacations/VacationHistory";
import AddNurseryIncomes from "./pages/financial/Nursery/AddNurseryIncome";
import AddNurseryExpense from "./pages/financial/Nursery/AddNurseryExpense";
import NurseryFinance from "./pages/financial/Nursery/NurseryFinance";
import AdminFinanceDeleted from "./pages/financial/AdminFinanceDeleted";
import SalesPlaces from "./Admin/SalesPlaces";
import NurseryControl from "./Admin/NurseryControl";
import ProductsControl from "./Admin/ProductsControl";
import AddWedding from "./pages/financial/Wedding/AddWedding";
import Weddings from "./pages/financial/Wedding/Weddings";
import AddWeddingConstants from "./pages/financial/Wedding/AddWeddingsConstants";
import AdminPage from "./Addiction/AdminPage";
import AddAddict from "./Addiction/Addict/AddAddicts";
import AddictTable from "./Addiction/Addict/AddictTable";
import AddSanatorium from "./Addiction/Sanatorium/AddSanatorium";
import SanatoriumTable from "./Addiction/Sanatorium/SanatoriumTable";
import AddFamilyCategory from "./Addiction/Family/AddFamilyCategory";
import FamilyContractTable from "./Addiction/Family/FamilyCategoryTable";
import AddFamilyExpense from "./Addiction/Family/AddFamilyExpense";
import FamilyExpenseTable from "./Addiction/Family/FamilyExpenseTable";
import Auth from "./Addiction/auth/auth";
import AddAdictForm from "./Addiction/addictForm/AddAddictForm";
import AddictFormTable from "./Addiction/addictForm/AddictFormTable";
import AddAddictFamily from "./Addiction/addictFamily/AddAddictFamily";
import AddAddictDonor from "./Addiction/addictDonor/AddAddictDonor";
import AddAdictFormDetails from "./Addiction/addictForm/AddictFormDetailes";
import AddictFamilyTable from "./Addiction/addictFamily/AddictFamilyTable";
import AddictDonorTable from "./Addiction/addictDonor/AddictDonorTable";
import AddFinancialFollowUp from "./Addiction/FianancialFollowUp/AddFinancialFollowUp";
import FinancialFollowUpTable from "./Addiction/FianancialFollowUp/FinancialFollowUpTable";
import Balance from "./Addiction/FianancialFollowUp/Balance";
import AddFormExpense from "./Addiction/FianancialFollowUp/AddFormExpense";
import TrackSales from "./pages/financial/Places/TrackSales";
import AddMission from "./pages/financial/Mission/AddMission";
import MissionHistory from "./pages/financial/Mission/MissionHistory";
import AddPermission from "./pages/financial/Permission/AddPermission";
import PermissionHistory from "./pages/financial/Permission/PermissionHistory";
import FormExpenseTable from "./Addiction/FianancialFollowUp/FormExpenseTable";
import AddIncome from "./Addiction/income/AddIncome";
import IncomeTable from "./Addiction/income/IncomeTable";
import FamilyBoxTable from "./Addiction/FamilyBox/FamilyBox";
import AddictDetails from "./Addiction/Addict/AddictDetails";
import AddReceipt from "./Addiction/Receipt/AddReceipt";
import ReceiptTable from "./Addiction/Receipt/ReceiptTable";
import UnderWorking from "./pages/financial/Working/UnderWorking";
import SanatoriumDetails from "./Addiction/Sanatorium/SanatoriumDetails";
import FatherSalary from "./FathersSalaries/FatherSalary";
import FatherAuth from "./FathersSalaries/FatherAuth";
import FatherServedSalary from "./FathersSalaries/FatherServedSalary";
import FatherDashboard from "./FathersSalaries/FatherDashboard";
import AddFatherSalary from "./FathersSalaries/AddFatherSalary";
import AmbitiousAuth from "./Ambitious/Auth/AmbitiousAuth";
import AmbitiousDashboard from "./Ambitious/AmbitiousDashboard";
import AddServed from "./Ambitious/Served/AddServed";
import AddServant from "./Ambitious/Servants/AddServant";
import AddContractInInventory from "./Ambitious/Contract/AddContract";
import AddDisability from "./Ambitious/Disability/AddDisability";
import AddSubDisability from "./Ambitious/SubDisability/AddSubDisability";
import AddProstheticDevices from "./Ambitious/ProstheticDevices/AddProstheticDevices";
import AddExpense from "./Ambitious/Expenses/AddExpense";
import AddAIncome from "./Ambitious/Incomes/AddIncome";
import AddInKindDonationsForm from "./Ambitious/InkindDonations/AddInKindDonations";
import ReturnsPlaces from "./Admin/ReturnsPlaces";
function App() {
  return (
    <div className="church-website">
      <Routes>
        <Route path="/" element={<Home />} />
        {/* <Route path="/add" element={<AddAdmin />} /> */}
        <Route path="login" element={<Login />} />
        <Route path="admin">
          <Route path="god" element={<AdminGod />} />
          {/* add pages  */}
          <Route path="add/all" element={<AdminAddPage />} />
          <Route path="add/place" element={<AddPlace />} />
          <Route path="add/category" element={<AddCategory />} />
          <Route path="add/product" element={<AddProduct />} />
          <Route path="add/worker" element={<AddWorker />} />
          <Route path="add/expenses" element={<AddExpenses />} />
          <Route path="add/incomes" element={<AddIncomes />} />
          <Route path="add/workersalary" element={<AddWorkerSalary />} />
          <Route path="add/advance" element={<AddAdvance />} />
          <Route path="add/penalety" element={<AddPenalety />} />
          <Route path="add/contract" element={<AddContract />} />
          <Route path="add/occasion" element={<AddOccasion />} />
          <Route path="add/blessing" element={<AddBlessing />} />
          <Route path="add/blessing/all" element={<AddBlessingAll />} />
          <Route path="add/vacation" element={<AddVacation />} />
          <Route path="add/wedding" element={<AddWedding />} />
          <Route path="add/wedding/constants" element={<AddWeddingConstants />} />
          <Route path="add/mission" element={<AddMission />} />
          <Route path="add/permission" element={<AddPermission />} />
          {/* history pages  */}
          <Route path="contract/history" element={<ContractHistroy />} />
          <Route path="servedcontract/history" element={<ServedContractHistory />} />
          <Route path="advance/history" element={<AdvanceHistory />} />
          <Route path="penalety/history" element={<PenaletyHistory />} />
          <Route path="blessing/history" element={<BlessingHistory />} />
          <Route path="vacation/history" element={<VacationHistory />} />
          <Route path="wedding/history" element={<Weddings />} />
          <Route path="mission/history" element={<MissionHistory />} />
          <Route path="permission/history" element={<PermissionHistory />} />
          {/* salaries */}
          <Route path="workersalary" element={<WorkerSalary />} />
          <Route path="servedsalary" element={<ServedSalary />} />
          <Route path="non-worker-salary" element={<NonWorkerSalary />} />
          <Route path="show/worker" element={<Workers />} />
          {/* update pages  */}
          <Route path="updateproduct" element={<UpdateProducts />} />
          <Route path="updatecategory" element={<UpdateCategory />} />
          <Route path="updateplace" element={<UpdatePlace />} />
          <Route path="updateoccasion" element={<UpdateOccasion />} />
          {/* finance  */}
          <Route path="finance" element={<AdminsFinance />} />
          <Route path="incomes" element={<IncomePage />} />
          <Route path="expenses" element={<ExpensePage />} />
          <Route path="sales" element={<SalesPlaces />} />
          <Route path="returns" element={<ReturnsPlaces />} />
        </Route>
        {/* palces  */}
        <Route path="salesandreturns/:place" element={<SalesAndReturnsPage />} />
        <Route path="salesPage/:place" element={<SalesPage />} />
        <Route path="trackSales/:place" element={<TrackSales />} />
        {/* locker  */}
        <Route path="finance" element={<AdminFinance />} />
        <Route path="finance/deleted" element={<AdminFinanceDeleted />} />
        <Route path="finance/accept" element={<NurseryControl />} />
        <Route path="finance/accept/products" element={<ProductsControl />} />
        <Route path="god">
          <Route path="add" element={<AddFamily />} />
          <Route path="search" element={<SearchFamily />} />
        </Route>
        <Route path="nursery/:place">
          <Route path="" element={<Nursury />} />
          <Route path="add/child" element={<AddChild />} />
          <Route path="add/list" element={<AddList />} />
          <Route path="add/incomes" element={<AddNurseryIncomes />} />
          <Route path="add/expenses" element={<AddNurseryExpense />} />
          <Route path="add/dailylist" element={<AddDailyList />} />
          <Route path="nursery/finance" element={<NurseryFinance />} />
          <Route path="children" element={<ChildrenData />} />
          <Route path="lists" element={<List />} />
          <Route path="dailylists" element={<DailyList />} />
        </Route>
        {/* Addiction pages */}
        <Route path="addiction" element={<Auth />}>
          <Route path="" element={<AdminPage />} />
          <Route path="add/addict" element={<AddAddict />} />
          <Route path="addict/details/:id" element={<AddictDetails />} />
          <Route path="addicts/:condition" element={<AddictTable />} />
          <Route path="add/sanatorium" element={<AddSanatorium />} />
          <Route path="sanatoriums" element={<SanatoriumTable />} />
          <Route path="sanatorium/details/:id" element={<SanatoriumDetails />} />
          <Route path="add/familyCategory" element={<AddFamilyCategory />} />
          <Route path="category" element={<FamilyContractTable />} />
          <Route path="add/expense" element={<AddFamilyExpense />} />
          <Route path="expenses" element={<FamilyExpenseTable />} />
          <Route path="add/addictform" element={<AddAdictForm />} />
          <Route path="addictform" element={<AddictFormTable />} />
          <Route path="addictform/:id" element={<AddAdictFormDetails />} />
          <Route path="add/addictFamily" element={<AddAddictFamily />} />
          <Route path="addictFamily" element={<AddictFamilyTable />} />
          <Route path="add/addictDonor" element={<AddAddictDonor />} />
          <Route path="addictDonor" element={<AddictDonorTable />} />
          <Route path="add/followUp" element={<AddFinancialFollowUp />} />
          <Route path="followUp" element={<FinancialFollowUpTable />} />
          <Route path="balance/:id" element={<Balance />} />
          <Route path="add/formExpense" element={<AddFormExpense />} />
          <Route path="formExpense" element={<FormExpenseTable />} />
          <Route path="add/income" element={<AddIncome />} />
          <Route path="incomes" element={<IncomeTable />} />
          <Route path="family-box" element={<FamilyBoxTable />} />
          <Route path="add/receipt" element={<AddReceipt />} />
          <Route path="receipt" element={<ReceiptTable />} />
        </Route>
        {/* Father salary pages */}
        <Route path="father/salary" element={<FatherAuth />}>
          <Route path="" element={<FatherDashboard />} />
          <Route path="add" element={<AddFatherSalary />} />
          <Route path="salary" element={<FatherSalary />} />
          <Route path="served" element={<FatherServedSalary />} />
        </Route>
        {/* Ambitious pages */}
        <Route path="ambitious" element={<AmbitiousAuth />}>
          <Route path="" element={<AmbitiousDashboard />} />
          <Route path="add/served" element={<AddServed />} />
          <Route path="add/servant" element={<AddServant />} />
          <Route path="add/contract" element={<AddContractInInventory />} />
          <Route path="add/disability" element={<AddDisability />} />
          <Route path="add-sub/disability" element={<AddSubDisability />} />
          <Route path="add/prostheticdevices" element={<AddProstheticDevices />} />
          <Route path="add/expense" element={<AddExpense />} />
          <Route path="add/income" element={<AddAIncome />} />
          <Route path="add/inkind-donation" element={<AddInKindDonationsForm />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
