import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link, useParams } from 'react-router-dom';
import Pagination from '../component/Pagination';

const AddictTable = () => {
    const [addicts, setAddicts] = useState([]);
    const { condition } = useParams();
    const [searchTerm, setSearchTerm] = useState("");

    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredAddicts = addicts.filter((addict) =>
        addict.addictName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        addict.addictPhone1.includes(searchTerm)
    );

    const paginatedAddicts = filteredAddicts.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredAddicts.length / itemsPerPage);

    const fetchAddicts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict`);
            const allAddicts = response.data;

            if (condition === 'recovered') {
                setAddicts(allAddicts.filter(addict => addict.addictExitDate && new Date(addict.addictExitDate) <= new Date()));
            } else if (condition === 'still') {
                setAddicts(allAddicts.filter(addict => !addict.addictExitDate || new Date(addict.addictExitDate) > new Date()));
            } else {
                setAddicts(allAddicts);
            }
        } catch (error) {
            console.error('Error fetching addicts:', error);
        }
    };

    const deleteAddict = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف الحالة بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}addict/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف حالة بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف الحالة بنجاح.', 'success');
                    fetchAddicts();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const downloadAddicts = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}addict/copies`, null, {
                responseType: 'blob',
            });
            const blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'addicts.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading CSV file:", error);
            Swal.fire('خطأ!', 'حدث خطأ أثناء تنزيل الملف.', 'error');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
    };

    useEffect(() => {
        fetchAddicts();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="w-[95%] mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">قائمة الحالات</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="ابحث عن الحالة (بالاسم او رقم الهاتف)"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex justify-between mb-4">
                    <Link to={'/addiction/add/addict'}>
                        <button
                            className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                        >
                            أضف حالة جديدة
                        </button>
                    </Link>
                    <button
                        onClick={downloadAddicts}
                        className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-green-700 focus:outline-none"
                    >
                        تحميل نسخة
                    </button>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">الكود</th>
                                <th className="px-4 py-3">الأسم</th>
                                <th className="px-4 py-3">الرقم القومي</th>
                                <th className="px-4 py-3">العنوان</th>
                                <th className="px-4 py-3">تاريخ الميلاد</th>
                                <th className="px-4 py-3">الخادم المسئول</th>
                                <th className="px-4 py-3">رقم الهاتف الاول</th>
                                <th className="px-4 py-3">رقم الهاتف التاني</th>
                                <th className="px-4 py-3">رقم الهاتف التالت</th>
                                <th className="px-4 py-3">تاريخ الدخول</th>
                                <th className="px-4 py-3">تاريخ الخروج</th>
                                <th className="px-4 py-3">الاستثناءات</th>
                                <th className="px-4 py-3">مواد التعاطي</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedAddicts.map((addict) => (
                                <tr key={addict._id} className="border-b hover:bg-gray-100 hover:cursor-pointer" onClick={() => window.location.href = `/addiction/addict/details/${addict._id}`}>
                                    <td className="px-4 py-3 text-center">{addict.addictCode || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictName || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictID || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictAddress || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictBirthDate ? new Date(addict.addictBirthDate).toLocaleDateString('en-uk') : 'لا يوجد'}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictResponsibleServer || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictPhone1 || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictPhone2 || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictPhone3 || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictEntryDate ? new Date(addict.addictEntryDate).toLocaleDateString('en-uk') : "لا يوجد تاريخ"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictExitDate ? new Date(addict.addictExitDate).toLocaleDateString('en-uk') : "لا يوجد تاريخ"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictExceptions?.join(', ') || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{addict.addictAddictions?.join(', ') || "لا يوجد"}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={(e) => { e.stopPropagation(); deleteAddict(addict._id); }}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
            <Pagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
            />
        </div>
    );
};

export default AddictTable;