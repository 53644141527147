import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const UpdateProducts = () => {
    const [products, setProducts] = useState([]);
    const [_id, set_id] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchCode, setSearchCode] = useState('');
    const [editingProduct, setEditingProduct] = useState(null);
    const [selectedPlace, setSelectedPlace] = useState('');
    const [newProductName, setNewProductName] = useState('');
    const [newQuantity, setNewQuantity] = useState('');
    const [newBuyPrice, setNewBuyPrice] = useState('');
    const [newSellPrice, setNewSellPrice] = useState('');
    const [newPlace, setNewPlace] = useState('');
    const [newCode, setNewCode] = useState('');
    const [places, setPlaces] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [errorMessage, setErrorMessage] = useState('');
    const productsPerPage = 20;

    const navigate = useNavigate();

    const fetchProducts = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/products`);
            setProducts(response.data.data);
        } catch (error) {
            console.error('Error fetching products:', error);
        }
    };

    const downloadProductCSV = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/products/csv`, {
                responseType: 'blob',
            });

            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'products.csv');
            document.body.appendChild(link);
            link.click();
            link.remove();
        } catch (error) {
            console.error("Error downloading CSV file:", error);
        }
    }

    const fetchPlaces = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/place`);
            setPlaces(response.data.data);
        } catch (error) {
            console.error('Error fetching places:', error);
        }
    };

    const handleEditClick = (product) => {
        setEditingProduct(product);
        set_id(product._id);
        setNewProductName(product.product);
        setNewQuantity(product.quantity);
        setNewBuyPrice(product.buyPrice);
        setNewSellPrice(product.sellPrice);
        setNewPlace(product.place._id);
        setNewCode(product.code);
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}add/update/product`, {
                _id,
                code: newCode,
                product: newProductName,
                quantity: newQuantity,
                buyPrice: newBuyPrice,
                sellPrice: newSellPrice,
                place: newPlace
            });
            setEditingProduct(null);
            fetchProducts();
        } catch (error) {
            console.error('Error updating product:', error);
            setErrorMessage(error.response?.data?.message || 'حدث خطأ أثناء تحديث المنتج');
        }
    };

    const filteredProducts = products.filter(product =>
        product.product.toLowerCase().includes(searchTerm.toLowerCase()) &&
        product.code.toLowerCase().includes(searchCode.toLowerCase()) &&
        (selectedPlace === '' || product.place._id === selectedPlace)
    );

    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);


    const handlePageInputChange = (e) => {
        const pageNumber = parseInt(e.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(filteredProducts.length / productsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredProducts.length / productsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleDelete = async (productId) => {
        const result = await Swal.fire({
            title: 'هل انت متأكد من الحذف النهائي؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        });
    
        if (result.isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_HOST_SERVER}add/delete/product/${productId}`);
                fetchProducts();
                Swal.fire(
                    'تم الحذف!',
                    'تم حذف المنتج بنجاح.',
                    'success'
                );
            } catch (error) {
                Swal.fire(
                    'حدث خطأ ما',
                    'حدث خطأ اثناء الحذف',
                    'error'
                );
                setErrorMessage(error.response?.data?.message || 'حدث خطأ أثناء حذف المنتج');
            }
        }
    };

    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (res.data !== 'خزنة') {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    const uniquePlaces = [...new Set(products.map(product => product.place?._id))]
        .map(id => products.find(product => product.place?._id === id)?.place)
        .filter(place => place);

    useEffect(() => {
        verifyAdmin();
    }, []);

    useEffect(() => {
        fetchProducts();
        fetchPlaces();
    }, []);

    return (
        <div className="p-6">
            <h1 className="text-3xl text-yellow-500 mb-6">إدارة المنتجات</h1>
            <input
                type="text"
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                placeholder="البحث بالاسم"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <input
                type="text"
                placeholder="البحث بالكود"
                value={searchCode}
                onChange={(e) => setSearchCode(e.target.value)}
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
            />
            <select
                className="border-b-2 border-yellow-500 w-full mb-6 p-2 focus:outline-none"
                value={selectedPlace}
                onChange={(e) => setSelectedPlace(e.target.value)}
            >
                <option value="">اختر المكان</option>
                {uniquePlaces.map((place, index) => (
                    <option key={index} value={place._id}>
                        {place.place}
                    </option>
                ))}
            </select>

            <table className="w-full text-right">
                <thead>
                    <tr>
                        <th className="text-yellow-500 p-2">كود المنتج</th>
                        <th className="text-yellow-500 p-2">اسم المنتج</th>
                        <th className="text-yellow-500 p-2">الكمية الاصلية</th>
                        <th className="text-yellow-500 p-2">الكمية</th>
                        <th className="text-yellow-500 p-2">سعر الشراء</th>
                        <th className="text-yellow-500 p-2">سعر البيع</th>
                        <th className="text-yellow-500 p-2">المكان</th>
                        <th className="text-yellow-500 p-2">إجراءات</th>
                    </tr>
                </thead>
                <tbody>
                    {currentProducts.map((product) => (
                        <tr key={product._id}>
                            <td className="p-2">{product.code}</td>
                            <td className="p-2">{product.product}</td>
                            <td className="p-2">{product.originalQuantity}</td>
                            <td className="p-2">{product.quantity}</td>
                            <td className="p-2">{product.buyPrice}</td>
                            <td className="p-2">{product.sellPrice}</td>
                            <td className="p-2">{product.place.place}</td>
                            <td className="">
                                <button
                                    onClick={() => handleEditClick(product)}
                                    className="text-yellow-500 underline me-3"
                                >
                                    تعديل
                                </button>
                                <button
                                    onClick={() => handleDelete(product._id)}
                                    className="text-red-500 underline"
                                >
                                    حذف
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <div className="print:hidden">
                <button
                    onClick={downloadProductCSV}
                    className="px-2 bg-lime-500 rounded-lg py-2 text-white mt-3"
                >تحميل ملف المنتجات</button>
            </div>
            <div className="mt-4 flex justify-center items-center">
                <button
                    onClick={handlePreviousPage}
                    className="px-3 py-1 border border-yellow-500 text-yellow-500 mx-2"
                >
                    السابق
                </button>

                <input
                    type="number"
                    min="1"
                    max={Math.ceil(filteredProducts.length / productsPerPage)}
                    value={currentPage}
                    onChange={handlePageInputChange}
                    className="w-12 text-center border border-yellow-500 mx-2"
                />

                <button
                    onClick={handleNextPage}
                    className="px-3 py-1 border border-yellow-500 text-yellow-500 mx-2"
                >
                    التالي
                </button>
            </div>

            {editingProduct && (
                <div className="mt-6">
                    <h2 className="text-xl text-yellow-500 mb-4">تعديل المنتج</h2>

                    <div className="mb-4">
                        <label className="text-yellow-500">كود المنتج:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newCode}
                            onChange={(e) => setNewCode(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">اسم المنتج:</label>
                        <input
                            type="text"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newProductName}
                            onChange={(e) => setNewProductName(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">الكمية:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newQuantity}
                            onChange={(e) => setNewQuantity(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">سعر الشراء:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newBuyPrice}
                            onChange={(e) => setNewBuyPrice(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">سعر البيع:</label>
                        <input
                            type="number"
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newSellPrice}
                            onChange={(e) => setNewSellPrice(e.target.value)}
                        />
                    </div>

                    <div className="mb-4">
                        <label className="text-yellow-500">المكان:</label>
                        <select
                            className="border-b-2 border-yellow-500 w-full p-2 focus:outline-none"
                            value={newPlace}
                            onChange={async (e) => {
                                setNewPlace(e.target.value);
                            }}
                        >
                            <option value="">اختر المكان</option>
                            {places.map((place) => (
                                <option key={place._id} value={place._id}>
                                    {place.place}
                                </option>
                            ))}
                        </select>
                    </div>

                    <button
                        onClick={handleSave}
                        className="text-yellow-500 underline mt-4"
                    >
                        حفظ
                    </button>

                    {errorMessage && (
                        <div className="text-red-500 text-center mt-6">
                            {errorMessage}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UpdateProducts;