import { Link } from "react-router-dom";
import { FaMoneyBillWave, FaPlusCircle, FaClipboardList } from "react-icons/fa";
import Navbar from "../components/Navbar";

const FatherDashboard = () => {
    return (
        <div className="p-6">
            <Navbar/>
            <div className="bg-gray-100 flex flex-col justify-center items-center">
                <h1 className="text-3xl font-bold text-gray-800 mb-6">لوحة التحكم</h1>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 w-full max-w-4xl">
                    <Link to="/father/salary/add" className="father-dashboard-card">
                        <FaPlusCircle className="text-5xl text-lime-600 mb-3" />
                        <h2 className="text-xl font-semibold">أضف مرتبا جديدا</h2>
                    </Link>

                    <Link to="/father/salary/salary" className="father-dashboard-card">
                        <FaClipboardList className="text-5xl text-lime-600 mb-3" />
                        <h2 className="text-xl font-semibold">صفحة المرتبات</h2>
                    </Link>

                    <Link to="/father/salary/served" className="father-dashboard-card">
                        <FaMoneyBillWave className="text-5xl text-lime-600 mb-3" />
                        <h2 className="text-xl font-semibold">صفحة المرتبات المصروفة</h2>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default FatherDashboard;
