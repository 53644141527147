import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { Link } from 'react-router-dom';
import Pagination from '../component/Pagination';

const FinancialFollowUpTable = () => {
    const [financialFollowUps, setFinancialFollowUps] = useState([]);
    const [editIndex, setEditIndex] = useState(null);
    const [updatedAmount, setUpdatedAmount] = useState('');
    const [updatedDate, setUpdatedDate] = useState('');
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleEditClick = (index, amount, date) => {
        setEditIndex(index);
        setUpdatedAmount(amount);
        setUpdatedDate(date);
    };

    const handleAmountChange = (e) => {
        setUpdatedAmount(e.target.value);
    };

    const handleDateChange = (e) => {
        setUpdatedDate(e.target.value);
    };

    const fetchFinancialFollowUps = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}follow-up`);
            setFinancialFollowUps(response.data);
        } catch (error) {
            console.error('Error fetching financial follow-ups:', error);
        }
    };

    const deleteFinancialFollowUp = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف هذه المتابعة المالية بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}follow-up/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف المتابعة المالية بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف المتابعة المالية بنجاح.', 'success');
                    fetchFinancialFollowUps();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleSaveClick = async (id) => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}follow-up/${id}`, { totalAmount: updatedAmount, FinancialFollowUpDate: updatedDate });
            Swal.fire('تم التحديث!', 'تم التحديث بنجاح', 'success');
            fetchFinancialFollowUps();
            setEditIndex(null);
        } catch (err) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء التحديث.', 'error');
        }
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredFinancialFollowUps = financialFollowUps.filter((followUp) =>
        followUp.AddictForm?.addict?.addictName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedFinancialFollowUps = filteredFinancialFollowUps.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredFinancialFollowUps.length / itemsPerPage);

    useEffect(() => {
        fetchFinancialFollowUps();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="container mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">قائمة المتابعات المالية</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="ابحث عن المتابعة المالية"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex justify-end mb-4">
                    <Link to={'/addiction/add/FollowUp'}>
                        <button className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none">
                            أضف متابعة مالية جديدة
                        </button>
                    </Link>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">#</th>
                                <th className="px-4 py-3">اسم المدمن</th>
                                <th className="px-4 py-3">المبلغ الشهري</th>
                                <th className="px-4 py-3">المبلغ المجمع</th>
                                <th className="px-4 py-3">يوم جمع الاستحقاقات</th>
                                <th className="px-4 py-3">التفاصيل</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedFinancialFollowUps.map((financialFollowUp, index) => {
                                const isLessCollected = financialFollowUp.totalAmountCollected < financialFollowUp.totalAmount;
                                const rowClass = isLessCollected ? 'bg-red-100' : 'bg-green-100';
                                return (
                                    <tr key={financialFollowUp._id} className={`border-b hover:bg-gray-100 ${rowClass}`}>
                                        <td className="px-4 py-3 text-center">{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                        <td className="px-4 py-3 text-center">{financialFollowUp.AddictForm?.addict?.addictName || 'لا يوجد'}</td>
                                        <td className="px-4 py-3 text-center">
                                            {editIndex === index ? (
                                                <input
                                                    type="number"
                                                    value={updatedAmount}
                                                    onChange={handleAmountChange}
                                                    className="border border-gray-300 p-2 rounded"
                                                />
                                            ) : (
                                                financialFollowUp.totalAmount || 'لا يوجد'
                                            )}
                                        </td>
                                        <td className="px-4 py-3 text-center">{financialFollowUp.totalAmountCollected || 'لا يوجد'}</td>
                                        <td className="px-4 py-3 text-center">
                                            {editIndex === index ? (
                                                <input
                                                    type="text"
                                                    value={updatedDate}
                                                    onChange={handleDateChange}
                                                    className="border border-gray-300 p-2 rounded"
                                                />
                                            ) : (
                                                financialFollowUp.FinancialFollowUpDate || 'لا يوجد'
                                            )}
                                        </td>
                                        <td className="px-4 py-3 text-center space-x-2">
                                            <Link to={'/addiction/balance/' + financialFollowUp._id}>
                                                <button
                                                    className="bg-blue-500 ms-3 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none"
                                                >
                                                    التفاصيل
                                                </button>
                                            </Link>
                                        </td>
                                        <td className="px-4 py-3 text-center space-x-2">
                                            {editIndex === index ? (
                                                <button onClick={() => handleSaveClick(financialFollowUp._id)} className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none">حفظ</button>
                                            ) : (
                                                <button onClick={() => handleEditClick(index, financialFollowUp.totalAmount, financialFollowUp.FinancialFollowUpDate)} className="bg-yellow-500 text-white py-1 px-3 rounded hover:bg-yellow-600 focus:outline-none">تعديل</button>
                                            )}
                                            <button
                                                onClick={() => deleteFinancialFollowUp(financialFollowUp._id)}
                                                className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                            >
                                                حذف
                                            </button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    );
};

export default FinancialFollowUpTable;