import { useState } from "react";

const AddServant = () => {
    const [formData, setFormData] = useState({
        name: "",
        birthDate: "",
        age: "",
        residence: "",
        qualifications: false,
        courseType: "",
        talents: "",
        servantPreparationCourse: false,
        notes: "",
        phone: "",
    });

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData({ ...formData, [name]: type === "checkbox" ? checked : value });
    };

    return (
        <div className="min-h-screen bg-gray-900 p-10 flex flex-col items-center text-white">
            <h1 className="text-4xl font-bold mb-8">الخدام</h1>
            <form className="bg-gray-800 p-6 rounded-xl shadow-lg w-full max-w-2xl">
                <div className="grid grid-cols-1 gap-4">
                    <input type="text" name="name" placeholder="الاسم" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="date" name="birthDate" placeholder="تاريخ الميلاد" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="number" name="age" placeholder="السن" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="text" name="residence" placeholder="السكن" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="qualifications" onChange={handleChange} /> موئهلات الخادم
                    </label>
                    <input type="text" name="courseType" placeholder="نوع الكورس" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <input type="text" name="talents" placeholder="مواهب الخدام" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} />
                    <label className="flex items-center gap-2">
                        <input type="checkbox" name="servantPreparationCourse" onChange={handleChange} /> كورس اعداد خدام
                    </label>
                    <textarea name="notes" placeholder="ملاحظات" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange}></textarea>
                    <input type="text" name="phone" placeholder="رقم التليفون" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <button type="submit" className="bg-blue-500 p-3 rounded w-full hover:bg-blue-600">إرسال</button>
                </div>
            </form>
        </div>
    );
};

export default AddServant;
