import Swal from 'sweetalert2';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from '../component/Pagination';

const SanatoriumTable = () => {
    const [sanatoriums, setSanatoriums] = useState([]);
    const [searchTerm, setSearchTerm] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const fetchSanatoriums = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST_SERVER}sanatorium`);
            const data = await response.json();
            setSanatoriums(data);
        } catch (error) {
            console.error('Error fetching sanatoriums:', error);
            alert('خطأ في جلب البيانات');
        }
    };

    const deleteSanatorium = async (id) => {
        Swal.fire({
            title: 'هل أنت متأكد؟',
            text: 'هل تريد حذف المصحة بشكل نهائي؟',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await axios.delete(`${process.env.REACT_APP_HOST_SERVER}sanatorium/${id}`);
                    await axios.post(`${process.env.REACT_APP_HOST_SERVER}notification`, {
                        Notifaction: `تم حذف مصحة بنجاح`,
                    });
                    Swal.fire('تم الحذف!', 'تم حذف المصحة بنجاح.', 'success');
                    fetchSanatoriums();
                } catch (err) {
                    Swal.fire('خطأ!', 'حدث خطأ أثناء الحذف.', 'error');
                }
            }
        });
    };

    const handleSearch = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const filteredSanatoriums = sanatoriums.filter((sanatorium) =>
        sanatorium.sanatoriumName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const paginatedSanatoriums = filteredSanatoriums.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const totalPages = Math.ceil(filteredSanatoriums.length / itemsPerPage);

    const downloadSanatoriums = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_HOST_SERVER}sanatorium/copies`, {
                method: 'POST'
            });
            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'sanatoriums.csv');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (error) {
            console.error("Error downloading CSV file:", error);
            alert('حدث خطأ أثناء تنزيل الملف');
        }
    };

    useEffect(() => {
        fetchSanatoriums();
    }, []);

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <div className="w-[95%] mx-auto">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">المصحات</h1>
                <div className="mb-4">
                    <input
                        type="text"
                        placeholder="ابحث عن المصحة"
                        value={searchTerm}
                        onChange={handleSearch}
                        className="w-full p-2 border rounded"
                    />
                </div>
                <div className="flex justify-between mb-4">
                    <button
                        onClick={() => window.location.href = '/addiction/add/sanatorium'}
                        className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none"
                    >
                        أضافة مصحة جديدة
                    </button>
                    <button
                        onClick={downloadSanatoriums}
                        className="bg-green-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-green-700 focus:outline-none"
                    >
                        تحميل نسخة
                    </button>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full bg-white rounded-xl shadow-lg">
                        <thead className="bg-blue-600 text-white">
                            <tr>
                                <th className="px-4 py-3">الأسم</th>
                                <th className="px-4 py-3">العنوان</th>
                                <th className="px-4 py-3">الإداري</th>
                                <th className="px-4 py-3">رقم هاتف الإداري</th>
                                <th className="px-4 py-3">الإداري المالي</th>
                                <th className="px-4 py-3">رقم هاتف الإداري المالي</th>
                                <th className="px-4 py-3">مصاريف التوصيل</th>
                                <th className="px-4 py-3">مسؤول التوصيل</th>
                                <th className="px-4 py-3">هاتف مسؤول التوصيل</th>
                                <th className="px-4 py-3">مصاريف الإقامة</th>
                                <th className="px-4 py-3">المصاريف الشخصية</th>
                                <th className="px-4 py-3">المصاريف الدواء</th>
                                <th className="px-4 py-3">مصاريف السجائر</th>
                                <th className="px-4 py-3">مصاريف التحاليل</th>
                                <th className="px-4 py-3">مصاريف الكانتين</th>
                                <th className="px-4 py-3">مصاريف الأشعة</th>
                                <th className="px-4 py-3">مصاريف أخرى</th>
                                <th className="px-4 py-3">مصاريف جلسات العلاج</th>
                                <th className="px-4 py-3">مصاريف الديتوكس</th>
                                <th className="px-4 py-3">مصاريف الأدوية الإضافية</th>
                                <th className="px-4 py-3">مصاريف التحاليل والأشعة الإضافية</th>
                                <th className="px-4 py-3">مصاريف العودة و الذهاب</th>
                                <th className="px-4 py-3">مصاريف تغيير المكان</th>
                                <th className="px-4 py-3">الاستثناءات</th>
                                <th className="px-4 py-3">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {paginatedSanatoriums.map((sanatorium) => (
                                <tr key={sanatorium._id} className="border-b hover:bg-gray-100 hover:cursor-pointer" onClick={() => window.location.href = `/addiction/sanatorium/details/${sanatorium._id}`}>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumName.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumAddress.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumAdmin.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumAdminPhone.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumFinanceAdmin.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumFinanceAdminPhone.slice(0,50)}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.delviryExpensee || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.delviryExpenseeresponsible.slice(0,50) || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.delviryExpenseeresponsiblePhone.slice(0,50) || "لا يوجد"}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.residenceExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.personalExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.medicalExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.cigiratesExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.medicalTestsExpenes || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.kantennExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.xraysExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.otherExpenses || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.therapySessionExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.DetoksExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.extramedicineExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.extramedicineTestsandxraysExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.goBackExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.changePlaceExpense || 0}</td>
                                    <td className="px-4 py-3 text-center">{sanatorium.sanatoriumExceptions.join(', ').slice(0,50) || "لا يوجد"}</td>
                                    <td className="px-4 py-3 flex justify-center space-x-2">
                                        <button
                                            onClick={(e) => { e.stopPropagation(); deleteSanatorium(sanatorium._id); }}
                                            className="bg-red-500 ms-3 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={handlePageChange}
                    />
                </div>
            </div>
        </div>
    );
};

export default SanatoriumTable;