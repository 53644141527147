import { FaUsers, FaBox, FaWheelchair, FaFileInvoiceDollar, FaClipboardList, FaList, FaUser, FaEllipsisH ,FaGift,FaGifts} from "react-icons/fa";
import { Link } from "react-router-dom";

const AmbitiousDashboard = () => {
    const addSections = [
        { title: "إضافة بيانات المخدومين", icon: <FaUsers />, bg: "bg-gradient-to-r from-blue-500 to-blue-700", link: "/ambitious/add/served" },
        { title: "إضافة مصاريف", icon: <FaFileInvoiceDollar />, bg: "bg-gradient-to-r from-green-500 to-green-700", link: "/ambitious/add/expense" },
        { title: "إضافة إيرادات", icon: <FaGifts />, bg: "bg-gradient-to-r from-green-300 to-green-500", link: "/ambitious/add/income" },
        { title: "إضافة تبرعات عينية", icon: <FaGift />, bg: "bg-gradient-to-r from-pink-500 to-pink-700", link: "/ambitious/add/inkind-donation" },
        { title: "إضافة الاجهزة التعويضية", icon: <FaWheelchair />, bg: "bg-gradient-to-r from-purple-500 to-purple-700", link: "/ambitious/add/prostheticdevices" },
        { title: "إضافة عهدة الخدمة", icon: <FaBox />, bg: "bg-gradient-to-r from-yellow-500 to-yellow-700", link: "/ambitious/add/contract" },
        { title: "إضافة أنواع الإعاقات", icon: <FaList />, bg: "bg-gradient-to-r from-red-500 to-red-700", link: "/ambitious/add/disability" },
        { title: "إضافة فئة فرعية للإعاقات", icon: <FaClipboardList />, bg: "bg-gradient-to-r from-indigo-500 to-indigo-700", link: "/ambitious/add-sub/disability" },
        { title: "إضافة الخدام", icon: <FaUser />, bg: "bg-gradient-to-r from-teal-500 to-teal-700", link: "/ambitious/add/servant" },
        { title: "إضافة أخرى", icon: <FaEllipsisH />, bg: "bg-gradient-to-r from-gray-500 to-gray-700", link: "/add-other" },
    ];
    
    const showSections = [
        { title: "عرض بيانات المخدومين", icon: <FaUsers />, bg: "bg-gradient-to-r from-blue-700 to-blue-900", link: "/show-data" },
        { title: "عرض صندوق الخدمة", icon: <FaFileInvoiceDollar />, bg: "bg-gradient-to-r from-green-700 to-green-900", link: "/show-fund" },
        { title: "عرض الاجهزة التعويضية", icon: <FaWheelchair />, bg: "bg-gradient-to-r from-purple-700 to-purple-900", link: "/show-devices" },
        { title: "عرض عهدة الخدمة", icon: <FaBox />, bg: "bg-gradient-to-r from-yellow-700 to-yellow-900", link: "/show-inventory" },
        { title: "عرض أخرى", icon: <FaEllipsisH />, bg: "bg-gradient-to-r from-gray-700 to-gray-900", link: "/show-other" },
        { title: "عرض أنواع الإعاقات", icon: <FaList />, bg: "bg-gradient-to-r from-red-700 to-red-900", link: "/show-disabilities" },
        { title: "عرض فئة فرعية للإعاقات", icon: <FaClipboardList />, bg: "bg-gradient-to-r from-indigo-700 to-indigo-900", link: "/show-subcategories" },
        { title: "عرض الخدام", icon: <FaUser />, bg: "bg-gradient-to-r from-teal-700 to-teal-900", link: "/show-servants" },
    ];

    return (
        <div className="min-h-screen bg-gray-900 p-10 flex flex-col items-center text-white">
            <h1 className="text-5xl font-extrabold text-center mb-12">لوحة التحكم</h1>
            <div className="w-full max-w-7xl">
                <h2 className="text-3xl font-bold mb-6 text-center">إضافة البيانات</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8 mb-12">
                    {addSections.map((section, index) => (
                        <Link to={section.link} key={index} className="block">
                            <div className={`p-8 ${section.bg} rounded-3xl shadow-2xl flex flex-col items-center justify-center transition-transform transform hover:scale-110 hover:shadow-xl duration-300`}>
                                <div className="text-5xl mb-3">{section.icon}</div>
                                <h2 className="text-xl font-bold text-center">{section.title}</h2>
                            </div>
                        </Link>
                    ))}
                </div>
                <h2 className="text-3xl font-bold mb-6 text-center">عرض البيانات</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
                    {showSections.map((section, index) => (
                        <Link to={section.link} key={index} className="block">
                            <div className={`p-8 ${section.bg} rounded-3xl shadow-2xl flex flex-col items-center justify-center transition-transform transform hover:scale-110 hover:shadow-xl duration-300`}>
                                <div className="text-5xl mb-3">{section.icon}</div>
                                <h2 className="text-xl font-bold text-center">{section.title}</h2>
                            </div>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default AmbitiousDashboard;