import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Navbar from "../../components/Navbar";
import Logo from "../../imgs/logo.png"

const AddictDetails = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        addictName: "",
        addictID: "",
        addictAddress: "",
        addictPhone1: "",
        addictPhone2: "",
        addictPhone3: "",
        addictEntryDate: "",
        addictExitDate: "",
        addictCode: "",
        addictResponsibleServer: "",
        addictBirthDate: "",
        addictAddictions: [""],
        addictExceptions: [""],
    });
    const [age, setAge] = useState('');
    const [isEditing, setIsEditing] = useState(false);

    useEffect(() => {
        const fetchAddictData = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict/${id}`);
                const addictData = response.data;
                const formatDateForInput = (date) => {
                    if (!date) return "";
                    const d = new Date(date);
                    return d.toISOString().split('T')[0];
                };
                
                setFormData({
                    addictName: addictData.addictName || "",
                    addictID: addictData.addictID || "",
                    addictAddress: addictData.addictAddress || "",
                    addictPhone1: addictData.addictPhone1 || "",
                    addictPhone2: addictData.addictPhone2 || "",
                    addictPhone3: addictData.addictPhone3 || "",
                    addictEntryDate: formatDateForInput(addictData.addictEntryDate),
                    addictExitDate: formatDateForInput(addictData.addictExitDate),
                    addictCode: addictData.addictCode || "",
                    addictResponsibleServer: addictData.addictResponsibleServer || "",
                    addictBirthDate: formatDateForInput(addictData.addictBirthDate),
                    addictAddictions: addictData.addictAddictions || [""],
                    addictExceptions: addictData.addictExceptions || [""],
                });
                if (addictData.addictBirthDate) {
                    const birthDate = new Date(addictData.addictBirthDate);
                    const calculatedAge = Math.floor((new Date() - birthDate) / (1000 * 60 * 60 * 24 * 365.25));
                    setAge(calculatedAge);
                }
            } catch (error) {
                console.error("Error fetching addict data:", error);
                showErrorAlert("حدث خطأ أثناء جلب بيانات الحالة.");
            }
        };

        fetchAddictData();
    }, [id]);

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handlePrint = () => {
        window.print();
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
        if (name === 'addictBirthDate') {
            const birthDate = new Date(value);
            const calculatedAge = Math.floor((new Date() - birthDate) / (1000 * 60 * 60 * 24 * 365.25));
            setAge(calculatedAge);
        }
    };

    const handleArrayChange = (e, field) => {
        setFormData(prev => ({
            ...prev,
            [field]: e.target.value.split(',').map(item => item.trim())
        }));
    };

    const handleSave = async () => {
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}addict/${id}`, formData);
            setIsEditing(false);
        } catch (error) {
            console.error('Error updating addict:', error);
            showErrorAlert("حدث خطأ أثناء حفظ التعديلات.");
        }
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <div className="my-4 hidden print:block">
                <img src={Logo} alt="لوجو" className="w-32 h-32" />
            </div>
            <div className="w-[90%] mx-auto p-8 bg-white rounded-xl shadow-lg">
                <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">تفاصيل الحالة</h1>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                    <div>
                        <label className="block text-lg font-medium mb-2">الأسم</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictName"
                                value={formData.addictName} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictName || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الرقم القومي</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictID"
                                value={formData.addictID} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictID || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">العنوان</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictAddress"
                                value={formData.addictAddress} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictAddress || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الأول</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictPhone1"
                                value={formData.addictPhone1} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone1 || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الثاني</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictPhone2"
                                value={formData.addictPhone2} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone2 || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">رقم الهاتف الثالث</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictPhone3"
                                value={formData.addictPhone3} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone3 || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الدخول</label>
                        {isEditing ? 
                            <input 
                                type="date" 
                                name="addictEntryDate"
                                value={formData.addictEntryDate} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictEntryDate ? new Date(formData.addictEntryDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الخروج</label>
                        {isEditing ? 
                            <input 
                                type="date" 
                                name="addictExitDate"
                                value={formData.addictExitDate} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictExitDate ? new Date(formData.addictExitDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">كود الحالة</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictCode"
                                value={formData.addictCode} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictCode || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الخادم المسئول</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictResponsibleServer"
                                value={formData.addictResponsibleServer} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictResponsibleServer || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">تاريخ الميلاد</label>
                        {isEditing ? 
                            <input 
                                type="date" 
                                name="addictBirthDate"
                                value={formData.addictBirthDate} 
                                onChange={handleInputChange}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictBirthDate ? new Date(formData.addictBirthDate).toLocaleDateString('en-uk') : "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">العمر</label>
                        <p className="w-full border rounded-lg px-4 py-3">{age || "لا يوجد"}</p>
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">مواد التعاطي</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictAddictions"
                                value={formData.addictAddictions.join(', ')} 
                                onChange={(e) => handleArrayChange(e, 'addictAddictions')}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictAddictions.join(', ') || "لا يوجد"}</p>
                        }
                    </div>
                    <div>
                        <label className="block text-lg font-medium mb-2">الاستثناءات</label>
                        {isEditing ? 
                            <input 
                                type="text" 
                                name="addictExceptions"
                                value={formData.addictExceptions.join(', ')} 
                                onChange={(e) => handleArrayChange(e, 'addictExceptions')}
                                className="w-full border rounded-lg px-4 py-3"
                            /> : 
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictExceptions.join(', ') || "لا يوجد"}</p>
                        }
                    </div>
                </div>
                <div className="text-center mt-4 space-x-4">
                    {isEditing ? (
                        <>
                            <button
                                className="bg-green-600 text-white px-4 py-2 rounded-lg w-[25%]"
                                onClick={handleSave}
                            >
                                حفظ
                            </button>
                            <button
                                className="bg-gray-600 text-white px-4 py-2 rounded-lg w-[25%]"
                                onClick={() => setIsEditing(false)}
                            >
                                إلغاء
                            </button>
                        </>
                    ) : (
                        <>
                            <button
                                className="bg-yellow-500 text-white px-4 py-2 rounded-lg w-[25%] mx-2"
                                onClick={() => setIsEditing(true)}
                            >
                                تعديل
                            </button>
                            <button
                                className="bg-green-600 text-white px-4 py-2 rounded-lg w-[25%] mx-2"
                                onClick={handlePrint}
                            >
                                طباعة
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AddictDetails;