import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import Navbar from '../../components/Navbar';
import { useParams } from 'react-router-dom';

const AddAdictFormDetails = () => {
    const { id } = useParams();
    const [formData, setFormData] = useState({
        _id: id,
        addict: '',
        addictAddress: '',
        addictPhone1: '',
        addictPhone2: '',
        addictPhone3: '',
        addictEntryDate: '',
        addictExitDate: '',
        responsibleServer: '',
        sanatorium: '',
        sanatoriumAddress: '',
        sanatoriumAdmin: '',
        sanatoriumAdminPhone: '',
        sanatoriumFinanceAdmin: '',
        sanatoriumFinanceAdminPhone: '',
        sanatoriumExceptions: [],
        delviryExpensee: '',
        delviryExpenseeresponsible: '',
        delviryExpenseeresponsiblePhone: '',
        residenceExpense: '',
        personalExpense: '',
        medicalExpense: '',
        cigiratesExpense: '',
        medicalTestsExpenes: '',
        xraysExpense: '',
        kantennExpense: '',
        otherExpenses: '',
        therapySessionExpense: '',
        DetoksExpense: '',
        extramedicineExpense: '',
        extramedicineTestsandxraysExpense: '',
        goBackExpense: '',
        changePlaceExpense: '',
        startDate: '',
        endDate: ''
    });

    const fetchData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}addict-form/${id}`);
            const data = response.data;
            setFormData({
                _id: id,
                addict: data.addict.addictName || '',
                addictAddress: data.addict.addictAddress || '',
                addictPhone1: data.addict.addictPhone1 || '',
                addictPhone2: data.addict.addictPhone2 || '',
                addictPhone3: data.addict.addictPhone3 || '',
                addictEntryDate: data.addict.addictEntryDate || '',
                addictExitDate: data.addict.addictExitDate || '',
                responsibleServer: data.responsibleServer || '',
                sanatorium: data.sanatorium.sanatoriumName || '',
                sanatoriumAddress: data.sanatorium.sanatoriumAddress || '',
                sanatoriumAdmin: data.sanatorium.sanatoriumAdmin || '',
                sanatoriumAdminPhone: data.sanatorium.sanatoriumAdminPhone || '',
                sanatoriumFinanceAdmin: data.sanatorium.sanatoriumFinanceAdmin || '',
                sanatoriumFinanceAdminPhone: data.sanatorium.sanatoriumFinanceAdminPhone || '',
                sanatoriumExceptions: data.sanatorium.sanatoriumExceptions || [],
                delviryExpensee: data.delviryExpensee || '',
                delviryExpenseeresponsible: data.delviryExpenseeresponsible || '',
                delviryExpenseeresponsiblePhone: data.delviryExpenseeresponsiblePhone || '',
                residenceExpense: data.residenceExpense || '',
                personalExpense: data.personalExpense || '',
                medicalExpense: data.medicalExpense || '',
                cigiratesExpense: data.cigiratesExpense || '',
                medicalTestsExpenes: data.medicalTestsExpenes || '',
                xraysExpense: data.xraysExpense || '',
                kantennExpense: data.kantennExpense || '',
                otherExpenses: data.otherExpenses || '',
                therapySessionExpense: data.therapySessionExpense || '',
                DetoksExpense: data.DetoksExpense || '',
                extramedicineExpense: data.extramedicineExpense || '',
                extramedicineTestsandxraysExpense: data.extramedicineTestsandxraysExpense || '',
                goBackExpense: data.goBackExpense || '',
                changePlaceExpense: data.changePlaceExpense || '',
                startDate: data.startDate || '',
                endDate: data.endDate || ''
            });
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء جلب البيانات.', 'error');
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: name.includes('Expense') && value !== '' ? Number(value) : value
        }));
    };

    const handleExceptionsChange = (e) => {
        setFormData(prev => ({
            ...prev,
            sanatoriumExceptions: e.target.value.split(',').map(item => item.trim())
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await axios.put(`${process.env.REACT_APP_HOST_SERVER}addict-form/${formData._id}`, formData);
            Swal.fire('نجاح!', 'تم التحديث بنجاح', 'success');
            fetchData();
        } catch (error) {
            Swal.fire('خطأ!', 'حدث خطأ أثناء تحديث البيانات.', 'error');
        }
    };

    const handlePrint = () => {
        window.print();
    };

    return (
        <div className="px-8 py-5 bg-gray-100 min-h-screen">
            <Navbar />
            <h1 className="text-4xl text-center font-extrabold my-10 text-blue-600">تفاصيل الاستمارة</h1>
            <div className='text-center'>
                <button onClick={handlePrint} className="bg-lime-500 text-white px-6 py-2 rounded-md mb-4">طباعة</button>
            </div>
            <div className="max-w-4xl lg:max-w-7xl xl:max-w-[90%] mx-auto p-8 bg-white rounded-xl shadow-lg">
                <form className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-6 print:hidden" onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="addict" className="block text-lg font-medium mb-2">الحالة</label>
                        <input
                            type="text"
                            id="addict"
                            name="addict"
                            value={formData.addict}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictAddress" className="block text-lg font-medium mb-2">عنوان الحالة</label>
                        <input
                            type="text"
                            id="addictAddress"
                            name="addictAddress"
                            value={formData.addictAddress}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone1" className="block text-lg font-medium mb-2">رقم هاتف الحالة الاول</label>
                        <input
                            type="text"
                            id="addictPhone1"
                            name="addictPhone1"
                            value={formData.addictPhone1}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone2" className="block text-lg font-medium mb-2">رقم هاتف الحالة التاني</label>
                        <input
                            type="text"
                            id="addictPhone2"
                            name="addictPhone2"
                            value={formData.addictPhone2}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictPhone3" className="block text-lg font-medium mb-2">رقم هاتف الحالة التالت</label>
                        <input
                            type="text"
                            id="addictPhone3"
                            name="addictPhone3"
                            value={formData.addictPhone3}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictEntryDate" className="block text-lg font-medium mb-2">تاريخ دخول الخدمة</label>
                        <input
                            type="text"
                            id="addictEntryDate"
                            name="addictEntryDate"
                            value={formData.addictEntryDate ? new Date(formData.addictEntryDate).toLocaleDateString('en-uk') : "لا يوجد"}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="addictExitDate" className="block text-lg font-medium mb-2">تاريخ خروج الخدمة</label>
                        <input
                            type="text"
                            id="addictExitDate"
                            name="addictExitDate"
                            value={formData.addictExitDate ? new Date(formData.addictExitDate).toLocaleDateString('en-uk') : "لا يوجد"}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatorium" className="block text-lg font-medium mb-2">المصحة</label>
                        <input
                            type="text"
                            id="sanatorium"
                            name="sanatorium"
                            value={formData.sanatorium}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAddress" className="block text-lg font-medium mb-2">عنوان المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAddress"
                            name="sanatoriumAddress"
                            value={formData.sanatoriumAddress}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdmin" className="block text-lg font-medium mb-2">مسئول المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAdmin"
                            name="sanatoriumAdmin"
                            value={formData.sanatoriumAdmin}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون مسئول المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumAdminPhone"
                            name="sanatoriumAdminPhone"
                            value={formData.sanatoriumAdminPhone}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdmin" className="block text-lg font-medium mb-2">المسئول المالي للمصحة</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdmin"
                            name="sanatoriumFinanceAdmin"
                            value={formData.sanatoriumFinanceAdmin}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumFinanceAdminPhone" className="block text-lg font-medium mb-2">رقم تليفون المسئول المالي للمصحة</label>
                        <input
                            type="text"
                            id="sanatoriumFinanceAdminPhone"
                            name="sanatoriumFinanceAdminPhone"
                            value={formData.sanatoriumFinanceAdminPhone}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="sanatoriumExceptions" className="block text-lg font-medium mb-2">استثناءات المصحة</label>
                        <input
                            type="text"
                            id="sanatoriumExceptions"
                            name="sanatoriumExceptions"
                            value={formData.sanatoriumExceptions.join(', ')}
                            onChange={handleExceptionsChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="responsibleServer" className="block text-lg font-medium mb-2">اسم الخادم المسؤول</label>
                        <input
                            type="text"
                            id="responsibleServer"
                            name="responsibleServer"
                            value={formData.responsibleServer}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpensee" className="block text-lg font-medium mb-2">تكاليف التوصيل</label>
                        <input
                            type="number"
                            id="delviryExpensee"
                            name="delviryExpensee"
                            value={formData.delviryExpensee ? formData.delviryExpensee : 0}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsible" className="block text-lg font-medium mb-2">المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsible"
                            name="delviryExpenseeresponsible"
                            value={formData.delviryExpenseeresponsible}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="delviryExpenseeresponsiblePhone" className="block text-lg font-medium mb-2">هاتف المسؤول عن التوصيل</label>
                        <input
                            type="text"
                            id="delviryExpenseeresponsiblePhone"
                            name="delviryExpenseeresponsiblePhone"
                            value={formData.delviryExpenseeresponsiblePhone}
                            onChange={handleChange}
                            readOnly
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="residenceExpense" className="block text-lg font-medium mb-2">مصاريف الاقامة بالمصحة</label>
                        <input
                            type="number"
                            id="residenceExpense"
                            name="residenceExpense"
                            value={formData.residenceExpense ? formData.residenceExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="personalExpense" className="block text-lg font-medium mb-2">المصاريف الشخصية داخل المصحة</label>
                        <input
                            type="number"
                            id="personalExpense"
                            name="personalExpense"
                            value={formData.personalExpense ? formData.personalExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalExpense" className="block text-lg font-medium mb-2">مصاريف الدواء</label>
                        <input
                            type="number"
                            id="medicalExpense"
                            name="medicalExpense"
                            value={formData.medicalExpense ? formData.medicalExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="cigiratesExpense" className="block text-lg font-medium mb-2">مصاريف السجاير</label>
                        <input
                            type="number"
                            id="cigiratesExpense"
                            name="cigiratesExpense"
                            value={formData.cigiratesExpense ? formData.cigiratesExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="kantennExpense" className="block text-lg font-medium mb-2">مصاريف الكانتين</label>
                        <input
                            type="number"
                            id="kantennExpense"
                            name="kantennExpense"
                            value={formData.kantennExpense ? formData.kantennExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="medicalTestsExpenes" className="block text-lg font-medium mb-2">مصاريف التحاليل</label>
                        <input
                            type="number"
                            id="medicalTestsExpenes"
                            name="medicalTestsExpenes"
                            value={formData.medicalTestsExpenes ? formData.medicalTestsExpenes : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="xraysExpense" className="block text-lg font-medium mb-2">مصاريف الاشعة</label>
                        <input
                            type="number"
                            id="xraysExpense"
                            name="xraysExpense"
                            value={formData.xraysExpense ? formData.xraysExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="otherExpenses" className="block text-lg font-medium mb-2">مصاريف اخري</label>
                        <input
                            type="number"
                            id="otherExpenses"
                            name="otherExpenses"
                            value={formData.otherExpenses ? formData.otherExpenses : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="therapySessionExpense" className="block text-lg font-medium mb-2">سعر الجلسات العلاجية داخل المصحة</label>
                        <input
                            type="number"
                            id="therapySessionExpense"
                            name="therapySessionExpense"
                            value={formData.therapySessionExpense ? formData.therapySessionExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="DetoksExpense" className="block text-lg font-medium mb-2">سعر الديتوكس</label>
                        <input
                            type="number"
                            id="DetoksExpense"
                            name="DetoksExpense"
                            value={formData.DetoksExpense ? formData.DetoksExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineExpense" className="block text-lg font-medium mb-2">سعر ادوية زيادة عن المحددة</label>
                        <input
                            type="number"
                            id="extramedicineExpense"
                            name="extramedicineExpense"
                            value={formData.extramedicineExpense ? formData.extramedicineExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="extramedicineTestsandxraysExpense" className="block text-lg font-medium mb-2">سعر اشاعات و تحاليل اضافية</label>
                        <input
                            type="number"
                            id="extramedicineTestsandxraysExpense"
                            name="extramedicineTestsandxraysExpense"
                            value={formData.extramedicineTestsandxraysExpense ? formData.extramedicineTestsandxraysExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="goBackExpense" className="block text-lg font-medium mb-2">مصاريف الذهاب و العودة</label>
                        <input
                            type="number"
                            id="goBackExpense"
                            name="goBackExpense"
                            value={formData.goBackExpense ? formData.goBackExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="changePlaceExpense" className="block text-lg font-medium mb-2">مصاريف التحويل من مكان الي مكان</label>
                        <input
                            type="number"
                            id="changePlaceExpense"
                            name="changePlaceExpense"
                            value={formData.changePlaceExpense ? formData.changePlaceExpense : 0}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-lg font-medium text-gray-700">تاريخ دخول المصحة</label>
                        <input
                            type="date"
                            name="startDate"
                            value={formData.startDate ? new Date(formData.startDate).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="block text-lg font-medium text-gray-700">تاريخ خروج المصحة</label>
                        <input
                            type="date"
                            name="endDate"
                            value={formData.endDate ? new Date(formData.endDate).toISOString().split('T')[0] : ''}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-3"
                        />
                    </div>
                    <div className='col-span-1 items-center self-end text-center'>
                        <button type="submit" className="bg-blue-600 text-white py-2 px-4 rounded-lg shadow-lg hover:bg-blue-700 focus:outline-none w-full">
                            حفظ التعديلات
                        </button>
                    </div>
                </form>
                <div className='hidden print:block'>
                    <div className="print:grid print:grid-cols-3 print:gap-4">
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">اسم الحالة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addict || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">الخادم المسؤول</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.responsibleServer || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">عنوان الحالة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictAddress || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">رقم تليفون الحالة 1</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone1 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">رقم تليفون الحالة 2</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone2 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">رقم تليفون الحالة 3</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictPhone3 || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">تاريخ دخول الحالة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictEntryDate ? new Date(formData.addictEntryDate).toLocaleDateString('en-uk') : 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">تاريخ خروج الحالة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.addictExitDate ? new Date(formData.addictExitDate).toLocaleDateString('en-uk') : 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">عنوان المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumAddress || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مدير المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumAdmin || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">هاتف مدير المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumAdminPhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مدير الشؤون المالية للمصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumFinanceAdmin || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">هاتف مدير الشؤون المالية للمصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumFinanceAdminPhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">استثناءات المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatoriumExceptions.join(', ') || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.sanatorium || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف التوصيل</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpensee || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">المسؤول عن مصاريف التوصيل</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpenseeresponsible || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">هاتف المسؤول عن مصاريف التوصيل</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.delviryExpenseeresponsiblePhone || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الإقامة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.residenceExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">المصاريف الشخصية</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.personalExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">المصاريف الطبية</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.medicalExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف السجائر</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.cigiratesExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الفحوصات الطبية</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.medicalTestsExpenes || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الأشعة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.xraysExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الكانتين</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.kantennExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف أخرى</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.otherExpenses || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف جلسات العلاج</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.therapySessionExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الديتوكس</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.DetoksExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الأدوية الإضافية</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.extramedicineExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف الفحوصات والأشعة الإضافية</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.extramedicineTestsandxraysExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">مصاريف العودة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.goBackExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">تغيير مكان المصاريف</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.changePlaceExpense || 'لا يوجد'}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">تاريخ دخول المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.startDate ? new Date(formData.startDate).toLocaleDateString("en-uk") : "لا يوجد"}</p>
                        </div>
                        <div className="col-span-1">
                            <label className="block text-lg font-medium text-gray-700">تاريخ خروج المصحة</label>
                            <p className="w-full border rounded-lg px-4 py-3">{formData.endDate ? new Date(formData.endDate).toLocaleDateString("en-uk") : "لا يوجد"}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddAdictFormDetails; 