import Swal from "sweetalert2";
import { useEffect, useState } from "react";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddWedding = () => {
    const [churches, setChurches] = useState([]);
    const [church2, setChurch2] = useState("");
    const [church, setChurch] = useState("");
    const [name, setName] = useState("");
    const [date, setDate] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [permitFees, setPermitFees] = useState(0);
    const [marriageContractFees, setMarriageContractFees] = useState(0);
    const [restOfFees, setRestOfFees] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [father, setFather] = useState("");
    const [assistant, setAssistant] = useState("");
    const [fatherPlessing, setFatherPlessing] = useState(0);
    const [assistantPlessing, setAssistantPlessing] = useState(0);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchChurches = async () => {
            try {
                const res = await axios.get(`${process.env.REACT_APP_HOST_SERVER}wedding-constant`);
                setChurches(res.data);
            } catch (error) {
                console.error("Error fetching churches:", error);
            }
        };

        fetchChurches();
    }, []);

    const handleChurchChange = (e) => {
        const selectedChurch = e.target.value;
        setChurch2(selectedChurch);

        const selectedChurchData = churches.find(ch => ch._id === selectedChurch);
        
        if (selectedChurchData) {
            setChurch(selectedChurchData.church);
            setMinutes(selectedChurchData.minutes);
            setPermitFees(selectedChurchData.permitFees);
            setMarriageContractFees(selectedChurchData.marriageContractFees);
            setRestOfFees(selectedChurchData.restOfFees);
            setCoupon(selectedChurchData.coupon);
            setFatherPlessing(selectedChurchData.fatherPlessing);
            setAssistantPlessing(selectedChurchData.assistantPlessing);
        }
    };

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}wedding`, {
                name,
                date,
                church,
                minutes,
                permitFees,
                marriageContractFees,
                restOfFees,
                coupon,
                father,
                assistant,
                fatherPlessing,
                assistantPlessing
            });
            setName("");
            setDate("");
            setChurch("");
            setMinutes(0);
            setPermitFees(0);
            setMarriageContractFees(0);
            setRestOfFees(0);
            setCoupon(0);
            setFather("");
            setAssistant("");
            setFatherPlessing(0);
            setAssistantPlessing(0);
            setFatherPlessing(0);
            setAssistantPlessing(0);
            showSuccessAlert("تم إضافة البيانات بنجاح!");
            setLoading(false);
        } catch (error) {
            showErrorAlert("حدث خطأ أثناء إضافة البيانات!");
            setLoading(false);
        }
    };

    return (
        <div className="w-11/12 mx-auto">
            <Navbar />
            <div className="add-heading my-8">
                <h1 className="text-center text-3xl font-bold">إضافة بيانات الزواج</h1>
            </div>
            <div className="form w-full md:w-1/2 mx-auto bg-white p-8 rounded-lg shadow-md">
                <form onSubmit={handleSubmit}>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">الكنيسة</label>
                        <select
                            value={church}
                            onChange={handleChurchChange}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        >
                            <option value="">اختر الكنيسة</option>
                            {churches.map((church) => (
                                <option key={church._id} value={church._id}>
                                    {church.church}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">الاسم</label>
                        <input
                            type="text"
                            value={name}
                            onChange={(e) => setName(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">التاريخ</label>
                        <input
                            type="date"
                            value={date}
                            onChange={(e) => setDate(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">الكنيسة</label>
                        <input
                            type="text"
                            value={church}
                            onChange={(e) => setChurch(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">القسيمة</label>
                        <input
                            type="number"
                            value={minutes}
                            onChange={(e) => setMinutes(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">المحضر</label>
                        <input
                            type="number"
                            value={coupon}
                            onChange={(e) => setCoupon(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">رسوم التصريح</label>
                        <input
                            type="number"
                            value={permitFees}
                            onChange={(e) => setPermitFees(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">رسوم عقد الزواج</label>
                        <input
                            type="number"
                            value={marriageContractFees}
                            onChange={(e) => setMarriageContractFees(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">باقي الرسوم</label>
                        <input
                            type="number"
                            value={restOfFees}
                            onChange={(e) => setRestOfFees(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">الاب الكاهن</label>
                        <input
                            type="text"
                            value={father}
                            onChange={(e) => setFather(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">المعلم</label>
                        <input
                            type="text"
                            value={assistant}
                            onChange={(e) => setAssistant(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">بركة الاب الكاهن</label>
                        <input
                            type="number"
                            value={fatherPlessing}
                            onChange={(e) => setFatherPlessing(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <div className="form-group mt-4">
                        <label className="block text-gray-700">بركة المعلم</label>
                        <input
                            type="number"
                            value={assistantPlessing}
                            onChange={(e) => setAssistantPlessing(e.target.value)}
                            className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                        />
                    </div>
                    <button type="submit" className="btn btn-primary mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600" disabled={loading}>
                        {loading ? "جارٍ الإضافة..." : "إضافة"}
                    </button>
                </form>
            </div>
        </div>
    );
};

export default AddWedding;
