import Swal from "sweetalert2";
import { useState, useEffect } from "react";
import Navbar from "../../../components/Navbar";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const AddWeddingConstants = () => {
    const [church, setChurch] = useState("");
    const [minutes, setMinutes] = useState(0);
    const [coupon, setCoupon] = useState(0);
    const [permitFees, setPermitFees] = useState(0);
    const [marriageContractFees, setMarriageContractFees] = useState(0);
    const [restOfFees, setRestOfFees] = useState(0);
    const [fatherPlessing, setFatherPlessing] = useState(0);
    const [assistantPlessing, setAssistantPlessing] = useState(0);
    const [loading, setLoading] = useState(false);
    const [constants, setConstants] = useState([]);
    const [editingConstant, setEditingConstant] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 5;
    const navigate = useNavigate();

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    const fetchConstants = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}wedding-constant`);
            setConstants(response.data);
        } catch (error) {
            showErrorAlert("حدث خطأ أثناء جلب البيانات!");
        }
    };

    useEffect(() => {
        fetchConstants();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}wedding-constant`, {
                church,
                minutes,
                coupon,
                permitFees,
                marriageContractFees,
                restOfFees,
                fatherPlessing,
                assistantPlessing
            });
            showSuccessAlert("تم إضافة البيانات بنجاح!");
            setLoading(false);
            fetchConstants();
        } catch (error) {
            showErrorAlert("حدث خطأ أثناء إضافة البيانات!");
            setLoading(false);
        }
    };

    const handleEdit = (constant) => {
        setEditingConstant(constant);
        setChurch(constant.church);
        setMinutes(constant.minutes);
        setCoupon(constant.coupon);
        setPermitFees(constant.permitFees);
        setMarriageContractFees(constant.marriageContractFees);
        setRestOfFees(constant.restOfFees);
        setFatherPlessing(constant.fatherPlessing);
        setAssistantPlessing(constant.assistantPlessing);
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.put(`${process.env.REACT_APP_HOST_SERVER}wedding-constant/${editingConstant._id}`, {
                church,
                minutes,
                coupon,
                permitFees,
                marriageContractFees,
                restOfFees,
                fatherPlessing,
                assistantPlessing
            });
            showSuccessAlert("تم تحديث البيانات بنجاح!");
            setLoading(false);
            setEditingConstant(null);
            fetchConstants();
        } catch (error) {
            showErrorAlert("حدث خطأ أثناء تحديث البيانات!");
            setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        const result = await Swal.fire({
            title: 'هل انت متأكد من الحذف النهائي؟',
            text: "لن تتمكن من التراجع عن هذا!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'نعم، احذفه!',
            cancelButtonText: 'إلغاء'
        });

        if (result.isConfirmed) {
            try {
                await axios.delete(`${process.env.REACT_APP_HOST_SERVER}wedding-constant/${id}`);
                showSuccessAlert("تم حذف البيانات بنجاح!");
                fetchConstants();
            } catch (error) {
                showErrorAlert("حدث خطأ أثناء حذف البيانات!");
            }
        }
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = constants.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(constants.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageInputChange = (e) => {
        const pageNumber = parseInt(e.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(constants.length / itemsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    return (
        <div className="min-h-screen bg-gray-100">
            <Navbar />
            <div className="container mx-auto py-8">
                <div className="bg-white p-8 rounded-lg shadow-md">
                    <h1 className="text-center text-3xl font-bold mb-6">إضافة بيانات الثوابت</h1>
                    <form onSubmit={editingConstant ? handleUpdate : handleSubmit}>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div className="form-group">
                                <label className="block text-gray-700">الكنيسة</label>
                                <input
                                    type="text"
                                    value={church}
                                    onChange={(e) => setChurch(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">القسيمة</label>
                                <input
                                    type="number"
                                    value={minutes}
                                    onChange={(e) => setMinutes(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">المحضر</label>
                                <input
                                    type="number"
                                    value={coupon}
                                    onChange={(e) => setCoupon(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">رسوم التصريح</label>
                                <input
                                    type="number"
                                    value={permitFees}
                                    onChange={(e) => setPermitFees(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">رسوم عقد الزواج</label>
                                <input
                                    type="number"
                                    value={marriageContractFees}
                                    onChange={(e) => setMarriageContractFees(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">باقي الرسوم</label>
                                <input
                                    type="number"
                                    value={restOfFees}
                                    onChange={(e) => setRestOfFees(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">بركة الاب الكاهن</label>
                                <input
                                    type="number"
                                    value={fatherPlessing}
                                    onChange={(e) => setFatherPlessing(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                            <div className="form-group">
                                <label className="block text-gray-700">بركة المعلم</label>
                                <input
                                    type="number"
                                    value={assistantPlessing}
                                    onChange={(e) => setAssistantPlessing(e.target.value)}
                                    className="form-control w-full mt-2 p-2 border border-gray-300 rounded-md"
                                />
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary mt-4 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600" disabled={loading}>
                            {loading ? "جارٍ الإضافة..." : editingConstant ? "تحديث" : "إضافة"}
                        </button>
                    </form>
                </div>
                <div className="constants-list mt-8">
                    <h2 className="text-2xl font-bold mb-4">قائمة بيانات الثوابت</h2>
                    <table className="w-full bg-white shadow-lg rounded-lg text-right">
                        <thead className="bg-yellow-300">
                            <tr>
                                <th className="p-2">الكنيسة</th>
                                <th className="p-2">القسيمة</th>
                                <th className="p-2">المحضر</th>
                                <th className="p-2">رسوم التصريح</th>
                                <th className="p-2">رسوم عقد الزواج</th>
                                <th className="p-2">باقي الرسوم</th>
                                <th className="p-2">بركة الاب الكاهن</th>
                                <th className="p-2">بركة المعلم</th>
                                <th className="p-2">الإجراءات</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((constant) => (
                                <tr key={constant._id} className="border-b">
                                    <td className="p-2">{constant.church}</td>
                                    <td className="p-2">{constant.minutes}</td>
                                    <td className="p-2">{constant.coupon}</td>
                                    <td className="p-2">{constant.permitFees}</td>
                                    <td className="p-2">{constant.marriageContractFees}</td>
                                    <td className="p-2">{constant.restOfFees}</td>
                                    <td className="p-2">{constant.fatherPlessing}</td>
                                    <td className="p-2">{constant.assistantPlessing}</td>
                                    <td className="">
                                        <button
                                            onClick={() => handleEdit(constant)}
                                            className="text-yellow-500 underline me-2"
                                        >
                                            تعديل
                                        </button>
                                        <button
                                            onClick={() => handleDelete(constant._id)}
                                            className="text-red-500 underline"
                                        >
                                            حذف
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="mt-4 flex justify-center items-center">
                        <button
                            onClick={handlePreviousPage}
                            className={`mx-1 p-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300' : 'bg-yellow-500 text-white'}`}
                            disabled={currentPage === 1}
                        >
                            السابق
                        </button>
                        <input
                            type="number"
                            value={currentPage}
                            onChange={handlePageInputChange}
                            className="mx-1 p-2 border border-yellow-300 rounded-lg w-16 text-center"
                        />
                        <span className="mx-1">صفحة {currentPage} من {Math.ceil(constants.length / itemsPerPage)}</span>
                        <button
                            onClick={handleNextPage}
                            className={`mx-1 p-2 rounded-lg ${currentPage === Math.ceil(constants.length / itemsPerPage) ? 'bg-gray-300' : 'bg-yellow-500 text-white'}`}
                            disabled={currentPage === Math.ceil(constants.length / itemsPerPage)}
                        >
                            التالي
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddWeddingConstants;