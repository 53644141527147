import Swal from "sweetalert2";
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';

const SalesPage = () => {
    const { place } = useParams();
    const [salesData, setSalesData] = useState([]);
    const [filteredSalesData, setFilteredSalesData] = useState([]);
    const [returnData, setReturnData] = useState({
        Returns_product: '',
        Returns_Quantity: 1,
        Returns_Price: 0,
        Returns_Place: place,
    });
    const [error, setError] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [filterDate, setFilterDate] = useState('');

    const showSuccessAlert = (text) => {
        Swal.fire({
            title: "نجاح!",
            text: text,
            icon: "success",
            confirmButtonText: "حسناً",
        }).then(() => {
            window.location.reload();
        });
    };

    const showErrorAlert = (text) => {
        Swal.fire({
            title: "خطأ!",
            text: text,
            icon: "error",
            confirmButtonText: "حاول مرة أخرى",
        });
    };

    useEffect(() => {
        const fetchSales = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_HOST_SERVER}get/sales`);
                setSalesData(response.data.data);
                setFilteredSalesData(response.data.data);
            } catch (err) {
                setError('حدث خطأ أثناء جلب البيانات');
            }
        };
        fetchSales();
    }, [place]);

    const handleReturnSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(`${process.env.REACT_APP_HOST_SERVER}add/returns`, returnData);
            showSuccessAlert('تم ارجاع البيانات بنجاح');
            setSalesData([]);
        } catch (err) {
            setError('حدث خطأ أثناء إرسال البيانات');
            showErrorAlert('حدث خطأ أثناء إرسال البيانات');
        }
    };

    const handleInputChange = (e) => {
        setReturnData({ ...returnData, [e.target.name]: e.target.value });
    };

    const handleDateChange = (e) => {
        const selectedDate = e.target.value;
        setFilterDate(selectedDate);
        const filteredData = salesData.filter(sale => sale.Sales_Time.slice(0, 10) === selectedDate);
        setFilteredSalesData(filteredData);
        setCurrentPage(1);
    };

    const handleProductChange = (e) => {
        const selectedProduct = e.target.value;
        const productData = salesData.find(sale => sale.Sales_product === selectedProduct);

        if (productData) {
            const unitPrice = productData.Sales_Price / productData.Sales_Quantity;
            setReturnData({
                ...returnData,
                Returns_product: selectedProduct,
                Returns_Price: unitPrice * returnData.Returns_Quantity,
            });
        }
    };

    const handleQuantityChange = (e) => {
        const quantity = parseFloat(e.target.value) || 0;
        setReturnData(prevState => {
            const productData = salesData.find(sale => sale.Sales_product === prevState.Returns_product);
            let newPrice = 0;
            if (productData) {
                const unitPrice = productData.Sales_Price / productData.Sales_Quantity;
                newPrice = unitPrice * quantity;
            }
            return {
                ...prevState,
                Returns_Quantity: quantity,
                Returns_Price: newPrice
            };
        });
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredSalesData.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredSalesData.length / itemsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handlePageInputChange = (e) => {
        const pageNumber = parseInt(e.target.value, 10);
        if (!isNaN(pageNumber) && pageNumber > 0 && pageNumber <= Math.ceil(filteredSalesData.length / itemsPerPage)) {
            setCurrentPage(pageNumber);
        }
    };

    const navigate = useNavigate('');
    const verifyAdmin = async () => {
        const token = sessionStorage.getItem('token');
        if (!token) {
            navigate('/login');
            return;
        }
        try {
            const res = await axios.post(`${process.env.REACT_APP_HOST_SERVER}admin/verify`, { token });
            if (place !== res.data) {
                navigate('/login');
            }
        } catch (err) {
            console.error(err);
            navigate('/login');
        }
    };

    useEffect(() => {
        verifyAdmin();
    }, []);

    return (
        <div className="p-4 min-h-screen">
            <h1 className="text-3xl font-bold mb-6 text-center">المبيعات في {place}</h1>

            <div className="mb-4">
                <label className="block mb-2">فلترة حسب التاريخ</label>
                <input
                    type="date"
                    value={filterDate}
                    onChange={handleDateChange}
                    className="w-full p-2 border border-yellow-300 rounded-lg"
                />
            </div>

            <div className="mb-8">
                {error && <p className="text-red-500">{error}</p>}
                {filteredSalesData.length > 0 ? (
                    <table className="w-full bg-white shadow-lg rounded-lg text-right">
                        <thead className="bg-yellow-300">
                            <tr>
                                <th className="p-2">الكود</th>
                                <th className="p-2">المنتج</th>
                                <th className="p-2">الكمية</th>
                                <th className="p-2">السعر</th>
                                <th className="p-2">الوقت</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.filter((item) => item.Sales_Place === place).map((sale, index) => (
                                <tr key={index} className="border-b">
                                    <td className="p-2">{sale.Sales_Code}</td>
                                    <td className="p-2">{sale.Sales_product}</td>
                                    <td className="p-2">{sale.Sales_Quantity}</td>
                                    <td className="p-2">{sale.Sales_Price} جنية</td>
                                    <td className="p-2">{sale.Sales_Time}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ) : (
                    <p>لا توجد مبيعات لهذا التاريخ.</p>
                )}

                <div className="mt-4 flex justify-center items-center">
                    <button
                        onClick={handlePreviousPage}
                        className={`mx-1 p-2 rounded-lg ${currentPage === 1 ? 'bg-gray-300' : 'bg-yellow-500 text-white'}`}
                        disabled={currentPage === 1}
                    >
                        السابق
                    </button>
                    <input
                        type="number"
                        value={currentPage}
                        onChange={handlePageInputChange}
                        className="mx-1 p-2 border border-yellow-300 rounded-lg w-16 text-center"
                    />
                    <span className="mx-1">صفحة {currentPage} من {Math.ceil(filteredSalesData.length / itemsPerPage)}</span>
                    <button
                        onClick={handleNextPage}
                        className={`mx-1 p-2 rounded-lg ${currentPage === Math.ceil(filteredSalesData.length / itemsPerPage) ? 'bg-gray-300' : 'bg-yellow-500 text-white'}`}
                        disabled={currentPage === Math.ceil(filteredSalesData.length / itemsPerPage)}
                    >
                        التالي
                    </button>
                </div>
            </div>

            <div className="bg-white p-6 rounded-lg shadow-lg">
                <h2 className="text-2xl font-bold mb-4">إرجاع المنتجات</h2>
                <form onSubmit={handleReturnSubmit}>
                    <div className="mb-4">
                        <label className="block mb-2">المنتج</label>
                        <select
                            name="Returns_product"
                            value={returnData.Returns_product}
                            onChange={handleProductChange}
                            className="w-full p-2 border border-yellow-300 rounded-lg"
                            required
                        >
                            <option value="">اختر المنتج</option>
                            {salesData.filter((item) => item.Sales_Place === place).map((sale, index) => (
                                <option key={index} value={sale.Sales_product}>
                                    {sale.Sales_product}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">الكمية</label>
                        <input
                            type="number"
                            name="Returns_Quantity"
                            value={returnData.Returns_Quantity}
                            onChange={handleQuantityChange}
                            className="w-full p-2 border border-yellow-300 rounded-lg"
                            required
                        />
                    </div>

                    <div className="mb-4">
                        <label className="block mb-2">السعر</label>
                        <input
                            type="number"
                            name="Returns_Price"
                            value={returnData.Returns_Price}
                            onChange={handleInputChange}
                            className="w-full p-2 border border-yellow-300 rounded-lg"
                            readOnly
                        />
                    </div>

                    <button type="submit" className="bg-yellow-500 text-white py-2 px-4 rounded-lg">
                        إرسال
                    </button>
                </form>
            </div>
        </div>
    );
};

export default SalesPage;