import { useState } from "react";

const AddInKindDonationsForm = () => {
    const [formData, setFormData] = useState({
        donationType: "",
        quantity: "",
        estimatedValue: "",
        donationDate: "",
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <div className="min-h-screen bg-gray-900 p-10 flex flex-col items-center text-white">
            <h1 className="text-4xl font-bold mb-8">إضافة تبرعات عينية</h1>
            <form className="bg-gray-800 p-6 rounded-xl shadow-lg w-full max-w-2xl">
                <div className="grid grid-cols-1 gap-4">
                    <input type="text" name="donationType" placeholder="صنف التبرع" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="number" name="quantity" placeholder="كمية التبرع" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="text" name="estimatedValue" placeholder="القيمة التقديرية للتبرع" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <input type="date" name="donationDate" className="p-3 rounded bg-gray-700 w-full" onChange={handleChange} required />
                    <button type="submit" className="bg-blue-500 p-3 rounded w-full hover:bg-blue-600">إرسال</button>
                </div>
            </form>
        </div>
    );
};

export default AddInKindDonationsForm;
